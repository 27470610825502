import React, { useMemo } from "react";
import {
    useDataGrid,
    EditButton,
    ShowButton,
    List,
    DateField,
    NumberField,
} from "@refinedev/mui";
import { DataGrid, GridColDef, getGridStringOperators } from "@mui/x-data-grid";
import { ptBR } from '@mui/x-data-grid/locales';
import { IResourceComponentsProps, useApiUrl, useCustom, useList, useMany, useNavigation, useTranslate } from "@refinedev/core";
import { canCreateOrder, canEditOrder, canViewOrder } from "../../utils";
import { StatusPedido } from "../../components/statuspedido";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import { IObra } from "../../interfaces";


export const PedidoList: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();

    const apiUrl = useApiUrl();
    const auth =   JSON.parse(localStorage.getItem("auth") || '{}');
    const aliasPerfilAdmin = ['adminObra','gerenteObra','coordObra'];

    const { data:dataPedidoUsuario, isLoading: pedUsuIsLoading } = useCustom({
        url: `${apiUrl}/pedidos/usuarios/`+(aliasPerfilAdmin.includes(auth.roles)?auth.id:-1),
        method: "get",
        config:{
            query:{
                type:(aliasPerfilAdmin.includes(auth.roles)?auth.id:'all')
            },         
        },        
      });
    const pedidosUsuario =  dataPedidoUsuario?.data ?? [];  
    const arrayPedidosUsuario = pedidosUsuario.map((pedido: { id: any; }) => pedido.id);   
    const pedidos =  aliasPerfilAdmin.includes(auth.roles)? (pedidosUsuario.filter((pedido: { id: any; }) => arrayPedidosUsuario.includes(pedido.id)).map((pedido: { id: any; }) => pedido.id) ?? []): (arrayPedidosUsuario ?? []); 

    const arrayObras = pedidosUsuario.map((pedido: { idObra: any; }) => pedido.idObra); 
    const arrayObrasPedido =  aliasPerfilAdmin.includes(auth.roles)? (pedidosUsuario.filter((pedido: { id: any; }) => arrayPedidosUsuario.includes(pedido.id)).map((pedido: { idObra: any; }) => pedido.idObra) ?? []): (arrayObras ?? []); 
    


    //const arrayObrasPedido = pedidos.map((pedido: { idObra: any; }) => pedido.idObra);


    const filterOperators = getGridStringOperators().filter(({ value }) =>
        ['contains' /* add more over time */ ].includes(value),
      );    

    const { dataGridProps, filters, setFilters, setCurrent} = useDataGrid({
        sorters: {
            initial: [
                {
                field: "dhPedido",
                order: "desc",
                },
             ],
            },  
        filters:{
            permanent:[
                // {
                //     field:"id",
                //     operator:"in",
                //     value:arrayPedidosUsuario
                // }
                {
                    field:"id",
                    operator:"in",
                    value:(aliasPerfilAdmin.includes(auth.roles)?(pedidos.length>0?pedidos:[-1]):null)
                }               
            ],
            initial: [
                {
                  field: "idUsuario",
                  operator: "eq",
                  value: auth.id,
                },
              ],  
        },
                    
    });
    const { show,edit } = useNavigation();

    const { data: grupoObrasData, isLoading: grupoObrasIsLoading } = useMany({
        resource: "gruposobras",
            ids: dataGridProps?.rows?.map((item: any) => item?.idGrupoObra) ?? [],
        queryOptions: {
            enabled: !!dataGridProps?.rows,
        },
    });    

    const { data: obrasData, isLoading: obrasIsLoading } = useMany({
        resource: "obras",
            ids: dataGridProps?.rows?.map((item: any) => item?.idObra) ?? [],
        queryOptions: {
            enabled: !!dataGridProps?.rows,
        },
    });  
    

    const { data: obrasFilterData } = useList<IObra>({
        resource: "obras",
        pagination: {
          mode: "off",
        },
        filters:[
            {
                field: "id",
                operator: "in",
                value: arrayObrasPedido,                
            }
        ],        
        sorters: [
            {
              field: "nomeObra",
              order: "asc",
            },
          ],        
      });
      const obras = obrasFilterData?.data || [];        
    
    // const { data: deptoData, isLoading: deptoIsLoading } = useMany({
    //     resource: "departamentos",
    //         ids: dataGridProps?.rows?.map((item: any) => item?.idDepartamento) ?? [],
    //     queryOptions: {
    //         enabled: !!dataGridProps?.rows,
    //     },
    // });    
    
    const { data: statusData, isLoading: statusIsLoading } = useMany({
        resource: "status",
            ids: dataGridProps?.rows?.map((item: any) => item?.idStatus) ?? [],
        queryOptions: {
            enabled: !!dataGridProps?.rows,
        },
    }); 

    const { data: usuariosData, isLoading: usuariosIsLoading } = useMany({
        resource: "usuarios",
            ids: dataGridProps?.rows?.map((item: any) => item?.idUsuarioResponsavel) ?? [],
        queryOptions: {
            enabled: !!dataGridProps?.rows,
        },
    });    

    const columns = React.useMemo<GridColDef[]>(
        () => [
            {
                field: "id",
                headerName: translate("pages.pedidos.fields.id"),
                type: "number",
                minWidth: 20,   
                filterOperators,             
            },
            {
                field: "numeroPedido",
                flex: 1,
                headerName: translate("pages.pedidos.fields.numeroPedido"),
                type: "number",
                minWidth: 120,
                filterOperators,
            },               
            {
                field: "numeroContrato",
                flex: 1,
                headerName: translate("pages.pedidos.fields.numeroContrato"),
                type: "number",
                minWidth: 120,
                filterOperators,
            },         
            
            {
                field: "idGrupoObra",
                flex: 1,
                headerName: translate(
                    "pages.pedidos.fields.idGrupoObra",
                ),
                valueGetter: ({ row }) => {
                    const value = row?.idGrupoObra;

                    return value;
                },                
                //type: "number",
                minWidth: 150,
                renderCell: function render({ value }) {
                    return grupoObrasIsLoading ? (
                        <>Loading...</>
                    ) : (
                        value + ' - ' + grupoObrasData?.data?.find((item) => item.id === value)
                        ?.nomeGrupoObra
                    );
                },      
                filterOperators,          
            },               
            {
                field: "idUsuarioResponsavel",
                flex: 1,
                headerName: translate(
                    "pages.gruposobras.fields.idUsuarioResponsavel",
                ),
                valueGetter: ({ row }) => {
                    const value = row?.idUsuarioResponsavel;

                    return value;
                },                
                //type: "number",
                minWidth: 200,
                renderCell: function render({ value }) {
                    return usuariosIsLoading ? (
                        <>Loading...</>
                    ) : (
                        value + ' - ' + usuariosData?.data?.find((item) => item.id === value)
                        ?.nomeUsuario
                    );
                },   
                filterable:false,               
            },   

            {
                field: "idObra",
                flex: 1,
                headerName: translate(
                    "pages.pedidos.fields.idObra",
                ),
                valueGetter: ({ row }) => {
                    const value = row?.idObra;

                    return value;
                },                
                //type: "number",
                minWidth: 200,
                renderCell: function render({ value }) {
                    return obrasIsLoading ? (
                        <>Loading...</>
                    ) : (
                        // value + ' - ' + obrasData?.data?.find((item) => item.id === value)
                        // ?.nomeObra
                        obrasData?.data?.find((item) => item.id === value)
                        ?.numeroInternoObra + ' - ' + obrasData?.data?.find((item) => item.id === value)
                        ?.nomeObra
                    );
                },      
                filterOperators,          
            },
            
            {
                field: "codigoInternoObra",
                flex: 1,
                headerName: translate("pages.obras.fields.codigoInternoObra"),
                minWidth: 150,
                filterOperators,
            },              

            // {
            //     field: "idDepartamento",
            //     flex: 1,
            //     headerName: translate(
            //         "pages.pedidos.fields.idDepartamento",
            //     ),
            //     valueGetter: ({ row }) => {
            //         const value = row?.idDepartamento;

            //         return value;
            //     },                
            //     //type: "number",
            //     minWidth: 200,
            //     renderCell: function render({ value }) {
            //         return deptoIsLoading ? (
            //             <>Loading...</>
            //         ) : (
            //             value + ' - ' + deptoData?.data?.find((item) => item.id === value)
            //             ?.nomeDepartamento
            //         );
            //     },                
            // },   

            {
                field: "nomeFornecedor",
                flex: 1,
                headerName: translate("pages.pedidos.fields.nomeFornecedor"),
                minWidth: 200,
                filterOperators,
            },    
            {
                field: "valorAtualContrato",
                flex: 1,
                headerName: translate("pages.pedidos.fields.valorAtualContrato"),
                type: "number",
                minWidth: 120,
                filterable:false,    
                renderCell: function render({ value }) {
                    return <NumberField value={parseFloat(0+value)}  options={{style: 'currency', currency: 'BRL', }}/>;
                },        
                      
            }, 
            {
                field: "valorAdicional",
                flex: 1,
                headerName: translate("pages.pedidos.fields.valorAdicional"),
                type: "number",
                minWidth: 120,
                filterable:false,                
                renderCell: function render({ value }) {
                    return <NumberField value={parseFloat(0+value)}  options={{style: 'currency', currency: 'BRL', }}/>;
                },                
            }, 
            {
                field: "valorNovoContrato",
                flex: 1,
                headerName: translate("pages.pedidos.fields.valorNovoContrato"),
                type: "number",
                minWidth: 120,
                filterable:false,                
                renderCell: function render({ value }) {
                    return <NumberField value={parseFloat(0+value)}  options={{style: 'currency', currency: 'BRL', }}/>;
                },                  
            },                         

            {
                field: "idStatus",
                flex: 1,
                headerName: translate(
                    "pages.pedidos.fields.idStatus",
                ),
                valueGetter: ({ row }) => {
                    const value = row?.idStatus;

                    return value;
                },                
                //type: "number",
                minWidth: 200,
                renderCell: function render({ value }) {
                    return <StatusPedido status={value} />
                },    
                filterable:false,            
            }, 

            {
                field: "dhPedido",
                flex: 1,
                headerName: translate("pages.pedidos.fields.dhPedido"),
                minWidth: 100,
                renderCell: function render({ value }) {
                    return <DateField value={value} format="DD/MM/YYYY" />;
                },
                filterable:false,
            },
            {
                field: "observPedido",
                flex: 1,
                headerName: translate("pages.pedidos.fields.observPedido"),
                minWidth: 200,
                filterable:false,
            },            
            {
                field: "actions",
                headerName: translate("table.actions"),
                sortable: false,                
                renderCell: function render({ row }) {
                    return (
                        <>
                            <EditButton 
                                hideText 
                                recordItemId={row.id}
                                disabled={!canViewOrder(row?.idStatus)}                                 
                                onClick={canViewOrder(row?.idStatus) ? 
                                        (e: React.PointerEvent<HTMLButtonElement>) => {
                                                edit("pedidos",row?.id);                              
                                            ;} 
                                    : ()=>{return false}}                                    
                            />
                            <ShowButton 
                                hideText 
                                recordItemId={row.id}
                                disabled={!canViewOrder(row?.idStatus)} 
                                onClick={canViewOrder(row?.idStatus) ? 
                                    (e: React.PointerEvent<HTMLButtonElement>) => {                         
                                            show("pedidos",row?.id);
                                        ;} 
                                : ()=>{return false}}                                 
                            />
                        </>
                    );
                },
                align: "center",
                headerAlign: "center",
                minWidth: 80,
                filterable:false,
            },
        ],
        [translate, filterOperators, grupoObrasIsLoading, grupoObrasData?.data, usuariosIsLoading, usuariosData?.data, obrasIsLoading, obrasData?.data, edit, show],
    );

    const obrasFilters = useMemo(() => {     
        let filterValues;
        
        const filter = filters.find((filter) => {
          if ("field" in filter) {
            return filter.field === "idObra";
          }
    
          return false;
        });
    
        if (Array.isArray(filter?.value)){
            filterValues = filter?.value?.map((value: string | number) =>
                Number(value),
              );
        }

        return {
          operator: filter?.operator || "in",
          value: (filterValues || []) as number[],
        };
      }, [filters]).value;
    
      const hasObrasFilter = obrasFilters?.length > 0;
    
      const handleOnTagClick = (obraId: number) => {
        const newFilters = obrasFilters;
        const hasCurrentFilter = newFilters.includes(obraId);
        if (hasCurrentFilter) {
          newFilters.splice(newFilters.indexOf(obraId), 1);
        } else {
          newFilters.push(obraId);
        }    
        setFilters([
          {
            field: "idObra",
            operator: "in",
            value: newFilters,
          },
        ]);
        setCurrent(1);
      };

    return (
        <List
            canCreate={canCreateOrder()}
        >

            {/* <Divider />         */}
            <Stack direction="row" spacing="12px" py="16px" sx={{overflowY:"hidden",overflowX:"auto",scrollbarWidth:"thin"}}>
                <Chip
                    color={hasObrasFilter ? undefined : "primary"}
                    sx={{
                    color: hasObrasFilter ? "undefined" : "white",
                    }}
                    label={`${translate("pages.pedidos.filters.allObras.label")}`}
                    onClick={() => {
                    setFilters([
                        {
                        field: "idObra",
                        operator: "in",
                        value: [],
                        },
                    ]);
                    setCurrent(1);
                    }}
                />
                {obras.map((obras) => {
                    return (
                    <Chip
                        key={obras.id}
                        label={obras.nomeObra}
                        color={
                        obrasFilters?.includes(obras.id) ? "primary" : undefined
                        }
                        sx={{
                        color: obrasFilters?.includes(obras.id)
                            ? "white"
                            : undefined,
                        }}
                        onClick={() => {
                        handleOnTagClick(obras.id);
                        }}
                    />
                    );
                })}
            </Stack>
            {/* <Divider />  */}

            <DataGrid 
                {...dataGridProps} 
                columns={columns} 
                autoHeight 
                sx={{
                    mt:"20px"
                }}                
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}  
            />
        </List>
    );
};