import React, { useMemo } from "react";
import { useApiUrl, useCustom, useList, useMany, useNavigation, useTranslate } from "@refinedev/core";
import { DateField, NumberField, useDataGrid } from "@refinedev/mui";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { DataGrid, GridColDef,getGridStringOperators } from "@mui/x-data-grid";
import { ptBR } from '@mui/x-data-grid/locales';

import { StatusPedido } from "../../statuspedido";
import { IPedido,IObra } from "../../../interfaces";
import Grid from "@mui/material/Grid";
import {Card as CustomCard} from "../../card"
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";

type Props = {
    obras: IObra[];
  } ;

export const PedidosRecentes = (props: Props) => {
    const translate = useTranslate();
    const { edit } = useNavigation();

    const apiUrl = useApiUrl();
    const auth =   JSON.parse(localStorage.getItem("auth") || '{}');
    const aliasPerfilAdmin = ['adminObra','gerenteObra','coordObra'];

    const { data:dataPedidoUsuario, isLoading: pedUsuIsLoading } = useCustom({
        url: `${apiUrl}/pedidos/usuarios/`+(aliasPerfilAdmin.includes(auth.roles)?auth.id:-1),
        method: "get",
        config:{
            query:{
                type:(aliasPerfilAdmin.includes(auth.roles)?auth.id:'all')
            },         
        },        
      });
    const pedidosUsuario =  dataPedidoUsuario?.data ?? [];  
    const arrayPedidosUsuario = pedidosUsuario.map((pedido: { id: any; }) => pedido.id);   
    const pedidos =  aliasPerfilAdmin.includes(auth.roles)? (pedidosUsuario.filter((pedido: { id: any; }) => arrayPedidosUsuario.includes(pedido.id)).map((pedido: { id: any; }) => pedido.id) ?? []): (arrayPedidosUsuario ?? []); 


    const { dataGridProps,filters,setFilters,setCurrent } = useDataGrid<IPedido>({
        resource: "pedidos",       
        permanentFilter: [
            // {
            //     field: "idStatus",
            //     operator: "ne",
            //     value: 12,
            // },
            {
                field:"id",
                operator:"in",
                value:(aliasPerfilAdmin.includes(auth.roles)?(pedidos.length>0?pedidos:[-1]):null)
            },     
            {
                field: "idStatus",
                operator: "in",
                value: (auth.roles==="aprovControl"?[6]:(auth.roles==="aprovSuperin"?[7]:[1,2,3,4,5,6,7,8,9,10,11]))
            }                      
        ],
        pagination: {
            pageSize: 5,
          },    
        sorters: {
        initial: [
            {
            field: "dhPedido",
            order: "desc",
            },
         ],
        },                         
        syncWithLocation: false,
    });

    const { data: grupoObrasData, isLoading: grupoObrasIsLoading } = useMany({
        resource: "gruposobras",
            ids: dataGridProps?.rows?.map((item: any) => item?.idGrupoObra) ?? [],
        queryOptions: {
            enabled: !!dataGridProps?.rows,
        },
    });   

    const { data: obrasData, isLoading: obrasIsLoading } = useMany({
        resource: "obras",
            ids: dataGridProps?.rows?.map((item: any) => item?.idObra) ?? [],
        queryOptions: {
            enabled: !!dataGridProps?.rows,
        },
    });           

    const { data: usuariosData, isLoading: usuariosIsLoading } = useMany({
        resource: "usuarios",
            ids: dataGridProps?.rows?.map((item: any) => item?.idUsuarioResponsavel) ?? [],
        queryOptions: {
            enabled: !!dataGridProps?.rows,
        },
    });        

    const filterOperators = getGridStringOperators().filter(({ value }) =>
        ['contains' /* add more over time */ ].includes(value),
      ); 

    const columns = React.useMemo<GridColDef[]>(
        () => [
            {
                field: "id",
                headerName: translate("pages.pedidos.fields.id"),
                type: "number",
                minWidth: 20,
                headerAlign:"center",
                renderCell: function render({ row }) {
                    return (
                            <Button
                                variant="text"
                                onClick={() => edit("pedidos", row.id)}
                                size="small"
                                sx={{ width: 80 }}
                            >
                                <Typography
                                    sx={{
                                        color: "text.primary",
                                        fontWeight: 700,
                                    }}
                                >
                                    #{row.id}
                                </Typography>
                            </Button>
                    );
                },    
                filterOperators,               
            },
            {
                field: "numeroPedido",
                flex: 1,
                headerName: translate("pages.pedidos.fields.numeroPedido"),
                type: "number",
                minWidth: 160,
                filterOperators, 
            },               
            {
                field: "numeroContrato",
                flex: 1,
                headerName: translate("pages.pedidos.fields.numeroContrato"),
                type: "number",
                minWidth: 160,
                filterOperators, 
            },       
            
            {
                field: "idGrupoObra",
                flex: 1,
                headerName: translate(
                    "pages.pedidos.fields.idGrupoObra",
                ),
                valueGetter: ({ row }) => {
                    const value = row?.idGrupoObra;

                    return value;
                },                
                //type: "number",
                minWidth: 150,
                renderCell: function render({ value }) {
                    return grupoObrasIsLoading ? (
                        <>Loading...</>
                    ) : (
                        value + ' - ' + grupoObrasData?.data?.find((item) => item.id === value)
                        ?.nomeGrupoObra
                    );
                },      
                filterOperators,          
            },               
            {
                field: "idUsuarioResponsavel",
                flex: 1,
                headerName: translate(
                    "pages.gruposobras.fields.idUsuarioResponsavel",
                ),
                valueGetter: ({ row }) => {
                    const value = row?.idUsuarioResponsavel;

                    return value;
                },                
                //type: "number",
                minWidth: 200,
                renderCell: function render({ value }) {
                    return usuariosIsLoading ? (
                        <>Loading...</>
                    ) : (
                        value + ' - ' + usuariosData?.data?.find((item) => item.id === value)
                        ?.nomeUsuario
                    );
                },    
                filterable:false,              
            },            
            {
                field: "idObra",
                flex: 1,
                headerName: translate(
                    "pages.pedidos.fields.idObra",
                ),
                valueGetter: ({ row }) => {
                    const value = row?.idObra;

                    return value;
                },                
                //type: "number",
                minWidth: 200,
                renderCell: function render({ value }) {
                    return obrasIsLoading ? (
                        <>Loading...</>
                    ) : (
                        // value + ' - ' + obrasData?.data?.find((item) => item.id === value)
                        // ?.nomeObra
                        obrasData?.data?.find((item) => item.id === value)
                        ?.numeroInternoObra + ' - ' + obrasData?.data?.find((item) => item.id === value)
                        ?.nomeObra
                    );
                },    
                filterOperators,             
            },   

            {
                field: "codigoInternoObra",
                flex: 1,
                headerName: translate("pages.obras.fields.codigoInternoObra"),
                minWidth: 150,
                filterOperators,
            },             

            {
                field: "nomeFornecedor",
                flex: 1,
                headerName: translate("pages.pedidos.fields.nomeFornecedor"),
                minWidth: 200,
                filterOperators, 
            },    
            {
                field: "valorAtualContrato",
                flex: 1,
                headerName: translate("pages.pedidos.fields.valorAtualContrato"),
                type: "number",
                minWidth: 180,
                renderCell: function render({ value }) {
                    return <NumberField value={parseFloat(value)}  options={{style: 'currency', currency: 'BRL', }}/>;
                },   
                filterable:false,               
            }, 
            {
                field: "valorAdicional",
                flex: 1,
                headerName: translate("pages.pedidos.fields.valorAdicional"),
                type: "number",
                minWidth: 120,
                renderCell: function render({ value }) {
                    return <NumberField value={parseFloat(value)}  options={{style: 'currency', currency: 'BRL', }}/>;
                },  
                filterable:false,              
            }, 
            {
                field: "valorNovoContrato",
                flex: 1,
                headerName: translate("pages.pedidos.fields.valorNovoContrato"),
                type: "number",
                minWidth: 120,
                renderCell: function render({ value }) {
                    return <NumberField value={parseFloat(value)}  options={{style: 'currency', currency: 'BRL', }}/>;
                },    
                filterable:false,              
            },                 
            {
                field: "dhPedido",
                flex: 1,
                headerName: translate("pages.pedidos.fields.dhPedido"),
                minWidth: 120,
                renderCell: function render({ value }) {
                    return <DateField value={value} format="DD/MM/YYYY"/>;
                },
                filterable:false,
            },
            {
                field: "idStatus",
                flex: 1,
                headerName: translate(
                    "pages.pedidos.fields.idStatus",
                ),
                valueGetter: ({ row }) => {
                    const value = row?.idStatus;

                    return value;
                },                
                //type: "number",
                minWidth: 200,
                renderCell: function render({ value }) {
                    return <StatusPedido status={value} />
                },   
                filterable:false,             
            },
            {
                field: "observPedido",
                flex: 1,
                headerName: translate("pages.pedidos.fields.observPedido"),
                minWidth: 200,                
                filterable:false,
            },              
        ],
        [translate, filterOperators, edit, grupoObrasIsLoading, grupoObrasData?.data, usuariosIsLoading, usuariosData?.data, obrasIsLoading, obrasData?.data],

    );

    const obrasFilters = useMemo(() => {     
        let filterValues;
        
        const filter = filters.find((filter) => {
          if ("field" in filter) {
            return filter.field === "idObra";
          }
    
          return false;
        });
    
        if (Array.isArray(filter?.value)){
            filterValues = filter?.value?.map((value: string | number) =>
                Number(value),
              );
        }

        return {
          operator: filter?.operator || "in",
          value: (filterValues || []) as number[],
        };
      }, [filters]).value;
    
      const hasObrasFilter = obrasFilters?.length > 0;
    
      const handleOnTagClick = (obraId: number) => {
        const newFilters = obrasFilters;
        const hasCurrentFilter = newFilters.includes(obraId);
        if (hasCurrentFilter) {
          newFilters.splice(newFilters.indexOf(obraId), 1);
        } else {
          newFilters.push(obraId);
        }    
        setFilters([
          {
            field: "idObra",
            operator: "in",
            value: newFilters,
          },
        ]);
        setCurrent(1);
      };
    

    return (
    <>
        <Grid item xs={24} md={16} xl={18}>            
            <CustomCard
                icon={<EventNoteOutlinedIcon />}
                title={translate("pages.dashboard.recentorders.title")}
                cardContentProps={{
                    sx: {
                        height: "100%", paddingX: { xs: 2 }
                    },
                }}
            >          

            {/* <Divider />         */}
            <Stack direction="row" spacing="12px" py="16px" sx={{overflowY:"hidden",overflowX:"auto",scrollbarWidth:"thin",ml:"24px"}}>
                <Chip
                    color={hasObrasFilter ? undefined : "primary"}
                    sx={{
                    color: hasObrasFilter ? "undefined" : "white",
                    }}
                    label={`${translate("pages.pedidos.filters.allObras.label")}`}
                    onClick={() => {
                    setFilters([
                        {
                        field: "idObra",
                        operator: "in",
                        value: [],
                        },
                    ]);
                    setCurrent(1);
                    }}
                />
                {props.obras.map((obras) => {
                    return (
                    <Chip
                        key={obras.id}
                        label={obras.nomeObra}
                        color={
                        obrasFilters?.includes(obras.id) ? "primary" : undefined
                        }
                        sx={{
                        color: obrasFilters?.includes(obras.id)
                            ? "white"
                            : undefined,
                        }}
                        onClick={() => {
                        handleOnTagClick(obras.id);
                        }}
                    />
                    );
                })}
            </Stack>
            {/* <Divider />  */}

                <DataGrid
                {...dataGridProps}
                columns={columns}
                autoHeight
                // columnHeaderHeight={0}
                rowHeight={90}
                pageSizeOptions={[5, 10, 25]}                
                sx={{
                    paddingX: { xs: 3 },
                    border: "none",
                    "& .MuiDataGrid-row": {
                        cursor: "pointer",
                    },
                    overflowY:"hidden",overflowX:"auto",
                    mt:"15px"
                }}
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText} />
            </CustomCard>
        </Grid>                        
    </>
    );
};