import React from "react";
import Grid from "@mui/material/Grid";
import { HttpError, useCustom, useList, useTranslate, useApiUrl } from "@refinedev/core";
import { Box, Typography } from "@mui/material";
import { PedidoTimeline } from "../../components/dashboard/pedidoTimeline";
import { PedidosRecentes } from "../../components/dashboard/pedidosRecentes";
import { PieChart } from "../../components/charts/PieChart";
import {Card as CustomCard} from "../../components/card"
import { IPedido,IObra} from "../../interfaces";
import WatchLaterOutlinedIcon from '@mui/icons-material/WatchLaterOutlined';


const totalRecordsPedido = (data: string | any[]):number=>{
    return data?.length
}

const totalRecordsAprovados = (data: any[]):number=>{
    return data?.filter(pedido => pedido.idStatus === 10).length
} 

const totalRecordsNAprovados = (data: any[]):number=>{
    return data?.filter(pedido => pedido.idStatus === 11).length
} 

const totalRecordsAndamento = (data: any[]):number=>{
    return data?.filter(pedido => [1,2,3,4,5,6,7,8,9].includes(pedido.idStatus)).length
} 

const totalRecordsAprovacao = (data: any[]):number=>{
    return data?.filter(pedido => [6,7].includes(pedido.idStatus)).length
} 


export const DashboardPage: React.FC = () => {
    const translate= useTranslate();
    const apiUrl = useApiUrl();
    const auth =   JSON.parse(localStorage.getItem("auth") || '{}');
    const aliasPerfilAdmin = ['adminObra','gerenteObra','coordObra'];

    
    const { data:dataPedidoUsuario, isLoading: pedUsuIsLoading } = useCustom({
        url: `${apiUrl}/pedidos/usuarios/${auth.id}`,
        method: "get",
        config:{
            query:{
                type:"dashboard"
            },         
        },
        queryOptions: {
            enabled: true,
        },        
      });


    const { data, isFetched, isError } = useList<IPedido, HttpError>({
        resource: "pedidos",
        pagination: {
            mode: "off",
          },
          filters: [
            {
              field: "idUsuario",
              operator: "eq",
              value: auth.id,
            },     
          ],          
    });

    const pedidosUsuario =  dataPedidoUsuario?.data ?? [];  
    const arrayPedidosUsuario = pedidosUsuario.map((pedido: { id: any; }) => pedido.id);    
    const pedidos =  aliasPerfilAdmin.includes(auth.roles)? (data?.data?.filter(pedido => arrayPedidosUsuario.includes(pedido.id)) ?? []): (data?.data ?? []);
    const arrayObrasPedido = pedidos.map((pedido: { idObra: any; }) => pedido.idObra);   

    const { data: obrasData } = useList<IObra>({
        resource: "obras",
        pagination: {
          mode: "off",
        },
        filters:[
            {
                field: "id",
                operator: "in",
                value: arrayObrasPedido,                
            }
        ],
        sorters: [
            {
              field: "nomeObra",
              order: "asc",
            },
          ],        
      });
      const obras = obrasData?.data || [];    


    return (
        <Grid container columns={24} spacing={2}>
            <Grid item xs={24} sm={24} md={24} lg={24} xl={24}>
                <Box>
                    <Typography variant="h5" fontWeight={400} sx={{ color: "text.primary", padding: "16px" }}>
                            {translate("pages.dashboard.title")}
                    </Typography>                    
                </Box>     
            </Grid>            
            <Grid item xs={24} sm={24} md={24} lg={24} xl={24}>
                <Box mt="20px" display="flex" flexWrap="wrap" gap={4}>
                    <PieChart
                        title={translate("pages.dashboard.piechart01.title")}
                        value={isFetched?totalRecordsAndamento(pedidos):0}
                        series={[(isFetched?(totalRecordsAndamento(pedidos)/totalRecordsPedido(pedidos))*100:0), 100-(isFetched?(totalRecordsAndamento(pedidos)/totalRecordsPedido(pedidos))*100:0)]}
                        colors={["#be2e38", "#e5abaf"]}
                    />
                    <PieChart
                        title={translate("pages.dashboard.piechart05.title")}
                        value={isFetched?totalRecordsAprovacao(pedidos):0}
                        series={[(isFetched?(totalRecordsAprovacao(pedidos)/totalRecordsPedido(pedidos))*100:0), 100-(isFetched?(totalRecordsAprovacao(pedidos)/totalRecordsPedido(pedidos))*100:0)]}
                        colors={["#be2e38", "#e5abaf"]}
                    />                    
                    <PieChart
                        title={translate("pages.dashboard.piechart02.title")}
                        value={isFetched?totalRecordsAprovados(pedidos):0}
                        series={[(isFetched?(totalRecordsAprovados(pedidos)/totalRecordsPedido(pedidos))*100:0), 100-(isFetched?(totalRecordsAprovados(pedidos)/totalRecordsPedido(pedidos))*100:0)]}
                        colors={["#be2e38", "#e5abaf"]}
                    />
                    <PieChart
                        title={translate("pages.dashboard.piechart03.title")}
                        value={isFetched?totalRecordsNAprovados(pedidos):0}
                        series={[(isFetched?(totalRecordsNAprovados(pedidos)/totalRecordsPedido(pedidos))*100:0), 100-(isFetched?(totalRecordsNAprovados(pedidos)/totalRecordsPedido(pedidos))*100:0)]}
                        colors={["#be2e38", "#e5abaf"]}
                    />
                </Box>                
            </Grid>

            <PedidosRecentes  obras={obras}/>

            <Grid item xs={24} md={8} xl={6}>
                <CustomCard
                    icon={<WatchLaterOutlinedIcon />}
                    title={translate("pages.dashboard.timeline.title")}
                >
                    <PedidoTimeline />
                </CustomCard>
            </Grid>
         
        </Grid>
    );
};